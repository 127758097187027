
import {
  Component, Prop, Vue, Ref, Watch,
} from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import DataTable from '@/components/DataTable/index.vue'

import ViewModel from '@/models/ViewModel'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import CreditMemo from '@/models/CreditMemo'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import homeTableFields from './home-table-fields'

@Component({
  components: {
    Widget,
    DataTable,
    IconAction,
    SearchInput,
  },
})
export default class CreditMemoHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public fields = homeTableFields

  public query: string[] = []

  // Selected rows
  public selected: string[] = []

  // dataTable field filters
  public fieldFilters: any = {}

  public ready: boolean = false

  public records: number = 0

  public memos: any = []

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public query_settings: any = {
    company_fields: [
      {
        name: 'client',
        key: 'client_id',
      },
    ],
    invoice_fields: [
      {
        name: 'invoice',
        key: 'invoice_id',
      },
    ],
    custom_fields: [
      {
        name: 'type:credit',
        value: 'type:credit',
      },
      {
        name: 'type:debit',
        value: 'type:debit',
      },
    ],
  }

  public mounted() {
    this.loadFilters()
  }

  public async rows(context: any): Promise<any> {
    this.loading = true
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    this.syncFilters()
    return CreditMemo.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters],
    }).then(result => {
      this.records = result.records
      this.loading = false
      this.memos = result.data
      return result.data
    })
  }

  public deleteMemo(row: any) {
    row.item.delete().then(() => {
      this.loading = false
      this.refresh()
    })
  }

  // Refresh dataTable
  public refresh() {
    this.dataTable.refresh()
  }

  // Reset filters
  public resetFilters() {
    this.fieldFilters = {}
    this.query = []
    this.clearFilters()
    this.refresh()
  }

  // Store session filters in VUEX
  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'credit_memos',
      data: { query: this.query, fieldFilters: this.fieldFilters },
    })
  }

  // Load filters from VUEX if present
  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('credit_memos').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
      }
      this.ready = true
    })
  }

  // Clear VUEX filters state
  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'credit_memos',
      data: null,
    })
  }
}
