export default [
  {
    key: 'checkbox',
    label: '',
    sortable: false,
    class: 'text-center align-middle',
    show: true,
    type: 'checkbox',
    thClass: 'align-middle text-center',
  },
  {
    key: 'id',
    label: 'ID',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
  },
  {
    key: 'number',
    label: 'Number',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },

  {
    key: 'type',
    label: 'Type',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },

  {
    key: 'client',
    label: 'Client',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
  },

  {
    key: 'invoice',
    label: 'Invoice',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'credit_memo_items',
    label: 'Items',
    sortable: false,
    class: 'text-center align-middle',
    show: false,
  },
  {
    key: 'total_amount',
    label: 'Total Amount',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    type: 'currency',
  },
  {
    key: 'action',
    label: 'Actions',
    sortable: false,
    filter: false,
    show: true,
    thClass: 'align-middle text-center',
    class: 'text-center align-middle',
  },
]
